export function overrideCard(theme) {
  theme.props.MuiCard = {
    elevation: 0
  };
  theme.overrides.MuiCardContent = {
    root: {
      '&:last-child': {
        paddingBottom: undefined
      }
    }
  };
}