import { dequal } from 'dequal/lite';
import { useLayoutEffect, useRef } from 'react';
export function useDeepEqualValue(value) {
  var ref = useRef(value);
  var isEqual = dequal(value, ref.current);
  useLayoutEffect(() => {
    if (!isEqual) {
      ref.current = value;
    }
  });
  return isEqual ? ref.current : value;
}