import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import { HTTPError } from "./HTTPError.js";
import { parseHTTPEndpoint } from "./parseHTTPEndpoint.js";

function defaultParseJSON(json) {
  return JSON.parse(json);
}

export function createHTTP(_temp) {
  var {
    fetch: fetcher,
    headers: defaultHeaders,
    baseURL: defaultBaseURL
  } = _temp === void 0 ? {} : _temp;

  function request(template, options) {
    var signal;
    var endpointOptions = {};

    if (options) {
      var _options = options;
      ({
        signal
      } = _options);
      endpointOptions = _objectWithoutPropertiesLoose(_options, ["signal"]);
      _options;
    }

    if (defaultBaseURL != null && endpointOptions.baseURL == null) {
      endpointOptions.baseURL = defaultBaseURL;
    }

    if (defaultHeaders != null) {
      endpointOptions.headers = Object.assign({}, defaultHeaders, endpointOptions.headers);
    }

    var endpoint = parseHTTPEndpoint(template, endpointOptions);
    var init = {
      method: endpoint.method,
      headers: endpoint.headers
    };

    if (signal != null) {
      init.signal = signal;
    }

    if (endpoint.body != null) {
      init.body = endpoint.body;
    }

    if (fetcher == null) {
      fetcher = fetch;
    }

    return fetcher(endpoint.url, init).then(response => {
      if (!response.ok) {
        throw new HTTPError(endpoint, response);
      }

      return response;
    });
  }

  function requestJSON(endpoint, options) {
    var endpointOptions = {};
    var parseJSON;

    if (options) {
      var _options2 = options;
      ({
        parseJSON
      } = _options2);
      endpointOptions = _objectWithoutPropertiesLoose(_options2, ["parseJSON"]);
      _options2;
    }

    if (parseJSON == null) {
      parseJSON = defaultParseJSON;
    }

    return request(endpoint, endpointOptions).then(response => response.text().then(parseJSON));
  }

  return {
    request,
    requestJSON
  };
}