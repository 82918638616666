import { useEffect, useRef } from 'react';
export function useWhenValueChanges(value, effect, isEqual) {
  if (isEqual === void 0) {
    isEqual = Object.is;
  }

  var ref = useRef(value);
  useEffect(() => {
    if (!isEqual(value, ref.current)) {
      effect(ref.current);
      ref.current = value;
    }
  });
}