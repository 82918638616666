import { parseHTTPEndpoint } from '@superdispatch/http';
export function inputToArgs(input) {
  return typeof input === 'string' ? [input] : input;
}
export function argsToKey(template, options) {
  var {
    url,
    body,
    method
  } = parseHTTPEndpoint(template, options);
  return [method, url, typeof body == 'string' ? body : undefined, options == null ? void 0 : options.key];
}
export function inputToKey(input) {
  var [template, options] = inputToArgs(input);
  return argsToKey(template, options);
}