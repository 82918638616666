var configOverrides = {
  '+': {
    prefix: '',
    skipEncoding: true
  },
  '#': {
    skipEncoding: true
  },
  '.': {
    separator: '.'
  },
  '/': {
    separator: '/'
  },
  ';': {
    separator: ';',
    withAssignment: true,
    skipEmptyAssignment: true
  },
  '?': {
    separator: '&',
    withAssignment: true
  },
  '&': {
    separator: '&',
    withAssignment: true
  }
};

function getOperatorConfig(operator) {
  return Object.assign({
    prefix: operator,
    separator: ','
  }, configOverrides[operator]);
}

function parseVariable(name) {
  /** @link https://tools.ietf.org/html/rfc6570#section-2.4.1 */
  var indexOfMaxLengthPrefix = name.indexOf(':');

  if (indexOfMaxLengthPrefix !== -1) {
    return {
      name: name.slice(0, indexOfMaxLengthPrefix),
      maxLength: parseInt(name.slice(indexOfMaxLengthPrefix + 1), 10)
    };
  }
  /** @link https://tools.ietf.org/html/rfc6570#section-2.4.2 */


  if (name.endsWith('*')) {
    return {
      isComposite: true,
      name: name.slice(0, -1)
    };
  }

  return {
    name
  };
}

function encodeString(value, skipEncoding) {
  if (skipEncoding) {
    return encodeURI(value) // Revert double encoding.
    .replace(/%25([0-9A-F]{2})/gi, '%$1');
  }

  return encodeURIComponent(value) // Encode exclamation sign.
  .replace(/!/g, '%21');
}

function encodeBaseParam(value, config) {
  if (value == null) {
    return null;
  }

  if (typeof value == 'number' && !Number.isFinite(value)) {
    return null;
  }

  if (value instanceof Date) {
    return value.toJSON();
  }

  return encodeString(String(value), config.skipEncoding);
}

function encodeListParam(param, config) {
  return param.map(value => encodeBaseParam(value, config)).join(config.separator);
}

function compositeToList(param) {
  return Object.entries(param).flat();
}

function isCompositeParam(param) {
  return typeof param === 'object' && Object.prototype.toString.call(param) === '[object Object]';
}

function encodeAssignment(key, value, config) {
  if (Array.isArray(value)) {
    value = encodeListParam(value, config);
  } else {
    value = encodeBaseParam(value, config);
  }

  if (value == null) {
    return null;
  }

  var result = encodeString(key, true);

  if (value || !config.skipEmptyAssignment) {
    result += "=" + value;
  }

  return result;
}

function encodeAssignmentEntries(entries, config) {
  var assignments = [];

  for (var [_key, _value] of entries) {
    var assignment = encodeAssignment(_key, _value, config);

    if (assignment != null) {
      assignments.push(assignment);
    }
  }

  if (assignments.length === 0) {
    return null;
  }

  return assignments.join(config.separator);
}

function encodeVariable(param, _ref, config) {
  var {
    name,
    maxLength,
    isComposite
  } = _ref;

  // Skip undefined values.
  if (param == null) {
    return null;
  }

  if (isCompositeParam(param)) {
    if (isComposite) {
      return encodeAssignmentEntries(Object.entries(param).sort((_ref2, _ref3) => {
        var [keyA] = _ref2;
        var [keyB] = _ref3;
        return keyA.localeCompare(keyB);
      }), config);
    }

    param = compositeToList(param);
  }

  if (Array.isArray(param)) {
    // Skip empty arrays.
    if (param.length === 0) {
      return null;
    }

    if (config.withAssignment) {
      if (isComposite) {
        return encodeAssignmentEntries(param.map(value => [name, value]), config);
      }

      return encodeAssignment(name, param, Object.assign({}, config, {
        separator: ','
      }));
    }

    return encodeListParam(param, isComposite ? config : Object.assign({}, config, {
      separator: ','
    }));
  } // Truncate string values with max length.


  if (typeof param === 'string' && Number.isInteger(maxLength)) {
    param = param.slice(0, maxLength);
  }

  if (config.withAssignment) {
    return encodeAssignment(name, param, config);
  }

  return encodeBaseParam(param, config);
} // Using `any` as a workaround for `Index signature is missing in type` error.
// eslint-disable-next-line @typescript-eslint/no-explicit-any


/**
 * @link https://tools.ietf.org/html/rfc6570
 */
export function parseURITemplate(template, params) {
  // Extract all values within `{…}` blocks
  return template.replace(/{(.*?)}/g, (_, input) => {
    var operator = '';

    if (/^[+#./;?&].+/.test(input)) {
      operator = input.slice(0, 1);
      input = input.slice(1);
    }

    var config = getOperatorConfig(operator);
    var values = [];

    for (var inputName of input.split(/,/g)) {
      var variable = parseVariable(inputName);

      var _value2 = encodeVariable(params[variable.name], variable, config);

      if (_value2 != null) {
        values.push(_value2);
      }
    }

    if (values.length === 0) {
      return '';
    }

    return config.prefix + values.join(config.separator);
  });
}