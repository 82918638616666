import { useLayoutEffect, useRef } from 'react';
export function useEventHandler(handler) {
  var mountRef = useRef(false);
  var callbackRef = useRef(handler);
  var {
    current: wrapper
  } = useRef(event => {
    var _callbackRef$current;

    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      if (!mountRef.current) {
        // eslint-disable-next-line no-console
        console.error('[useEventHandler]: "handler" was called during the render. ' + 'This can lead to stale closure problems.');
      }
    }

    (_callbackRef$current = callbackRef.current) === null || _callbackRef$current === void 0 ? void 0 : _callbackRef$current.call(callbackRef, event);
  });
  useLayoutEffect(() => {
    mountRef.current = true;
    callbackRef.current = handler;
    return () => {
      mountRef.current = false;
    };
  });
  return wrapper;
}