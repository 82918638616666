import { cache, mutate as mutateSWR } from 'swr';
import { inputToKey } from "./internal/utils.js";
export function revalidateHTTPResource(input) {
  return mutateSWR(inputToKey(input));
}
export function mutateHTTPResource(input, fn, shouldRevalidate) {
  return mutateSWR(inputToKey(input), fn, shouldRevalidate);
}
export function clearHTTPResourceCache() {
  cache.clear();
}