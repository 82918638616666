export var Color;

(function (Color) {
  Color["White"] = "#fff";
  Color["White50"] = "rgba(255, 255, 255, 0.5)";
  Color["White40"] = "rgba(255, 255, 255, 0.4)";
  Color["White20"] = "rgba(255, 255, 255, 0.2)";
  Color["White10"] = "rgba(255, 255, 255, 0.1)";
  Color["White08"] = "rgba(255, 255, 255, 0.08)";
  Color["Transparent"] = "rgba(0, 0, 0, 0)";
  Color["Black"] = "#000";
  Color["Black50"] = "rgba(0, 0, 0, 0.5)";
  Color["Black20"] = "rgba(0, 0, 0, 0.2)";
  Color["Grey100"] = "#8F949E";
  Color["Grey200"] = "#6A707C";
  Color["Grey300"] = "#5B6371";
  Color["Grey400"] = "#323C4E";
  Color["Grey450"] = "#222F44";
  Color["Grey500"] = "#192334";
  Color["Silver100"] = "#F6F7F8";
  Color["Silver200"] = "#F3F5F8";
  Color["Silver300"] = "#E8ECF0";
  Color["Silver400"] = "#E1E5EA";
  Color["Silver500"] = "#C4CDD5";
  Color["Blue50"] = "#EBF4FF";
  Color["Blue75"] = "#CCE5FF";
  Color["Blue100"] = "#A8D1FF";
  Color["Blue200"] = "#66ADFF";
  Color["Blue300"] = "#0075FF";
  Color["Blue400"] = "#0063DB";
  Color["Blue500"] = "#0050B2";
  Color["Green50"] = "#ECF9EF";
  Color["Green75"] = "#C8F4D1";
  Color["Green100"] = "#90EAAE";
  Color["Green200"] = "#5DDA87";
  Color["Green300"] = "#1FA84D";
  Color["Green400"] = "#1E8F46";
  Color["Green500"] = "#19803D";
  Color["Purple50"] = "#EFEEFC";
  Color["Purple75"] = "#DCDBF5";
  Color["Purple100"] = "#CBC8EE";
  Color["Purple200"] = "#A7A1E8";
  Color["Purple300"] = "#6559CF";
  Color["Purple400"] = "#473ABB";
  Color["Purple500"] = "#3A2F9D";
  Color["Red50"] = "#FFEDEB";
  Color["Red75"] = "#FDD9D3";
  Color["Red100"] = "#FDC2BA";
  Color["Red200"] = "#FE988B";
  Color["Red300"] = "#EE3017";
  Color["Red400"] = "#D9210D";
  Color["Red500"] = "#C31909";
  Color["Teal50"] = "#E3F6FC";
  Color["Teal75"] = "#BEEDF9";
  Color["Teal100"] = "#91E3F8";
  Color["Teal200"] = "#61D3EF";
  Color["Teal300"] = "#00A0CC";
  Color["Teal400"] = "#008DB8";
  Color["Teal500"] = "#007DA3";
  Color["Yellow50"] = "#FFF9E5";
  Color["Yellow75"] = "#FFF1C2";
  Color["Yellow100"] = "#FFE494";
  Color["Yellow200"] = "#FFDC6B";
  Color["Yellow300"] = "#FFCB47";
  Color["Yellow400"] = "#FFA91F";
  Color["Yellow500"] = "#DB7500";
})(Color || (Color = {}));

export function isColorProp(name) {
  return typeof name == 'string' && Object.prototype.hasOwnProperty.call(Color, name);
}