import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { useIsMounted, useValueObserver } from '@superdispatch/hooks';
import { useFormik } from 'formik';
import { useFormsContext } from "./FormsProvider.js";
export function useFormikEnhanced(_ref) {
  var {
    key,
    onSubmit: _onSubmit,
    onSubmitSuccess,
    onSubmitFailure,
    getFormErrors: getFormErrorsOption,
    enableReinitialize = true,
    initialStatus = {
      type: 'initial'
    }
  } = _ref,
      config = _objectWithoutProperties(_ref, ["key", "onSubmit", "onSubmitSuccess", "onSubmitFailure", "getFormErrors", "enableReinitialize", "initialStatus"]);

  var ctx = useFormsContext();
  var isMounted = useIsMounted();
  var getFormErrors = getFormErrorsOption || ctx.getFormErrors;
  var formik = useFormik(_objectSpread(_objectSpread({}, config), {}, {
    initialStatus,
    enableReinitialize,
    onSubmit: (values, _ref2) => {
      var {
        setErrors,
        setStatus
      } = _ref2;
      return Promise.resolve(_onSubmit(values)).then(response => ({
        type: 'submitted',
        payload: response
      }), error => ({
        type: 'rejected',
        payload: error
      })).then(status => {
        if (isMounted()) {
          setStatus(status);

          if (status.type === 'rejected' && getFormErrors) {
            setErrors(getFormErrors(status.payload));
          }
        }
      });
    }
  }));
  useValueObserver(key, () => {
    if (formik.dirty) {
      formik.resetForm();
    }
  });
  useValueObserver(formik.status, () => {
    if (formik.status.type === 'submitted') {
      onSubmitSuccess === null || onSubmitSuccess === void 0 ? void 0 : onSubmitSuccess(formik.status.payload, formik.values);
    }

    if (formik.status.type === 'rejected') {
      onSubmitFailure === null || onSubmitFailure === void 0 ? void 0 : onSubmitFailure(formik.status.payload, formik.values);
    }
  });
  return formik;
}