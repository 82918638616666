import { useDeepEqualMemo } from '@superdispatch/hooks';
import useSWR from 'swr';
import { argsToKey, inputToArgs } from "./internal/utils.js";
export function useHTTPResource(input, fetcher, options) {
  var [key, template, params] = useDeepEqualMemo(() => {
    if (input == null) {
      return [null];
    }

    var [nextTemplate, nextParams] = inputToArgs(input);
    var nextKey = argsToKey(nextTemplate, nextParams);
    return [nextKey, nextTemplate, nextParams];
  }, [input]);
  return useSWR(key, Object.assign({}, options, {
    fetcher: !template ? undefined : () => fetcher(template, params)
  }));
}