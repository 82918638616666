import { useRef } from 'react';
export function useConstant(factory) {
  var ref = useRef(null);

  if (ref.current == null) {
    ref.current = {
      current: factory()
    };
  }

  return ref.current.current;
}