import { parseURITemplate } from '@superdispatch/uri';
var DEFAULT_METHOD = 'GET';
var METHOD_PATTERN = /^([\w]+) (.+)/;

function parseEndpointTemplate(url, params) {
  var method = DEFAULT_METHOD;
  var matches = METHOD_PATTERN.exec(url);

  if (matches) {
    [, method, url] = matches;
  }

  if (params != null) {
    url = parseURITemplate(url, params);
  }

  return [method.toUpperCase(), url];
}

export function parseHTTPEndpoint(template, options) {
  var endpoint = {
    headers: {},
    url: template,
    method: DEFAULT_METHOD
  };

  if (options == null) {
    [endpoint.method, endpoint.url] = parseEndpointTemplate(template);
  } else {
    var {
      json,
      body,
      baseURL,
      headers
    } = options;
    [endpoint.method, endpoint.url] = parseEndpointTemplate(template, options);

    if (baseURL) {
      endpoint.url = baseURL + endpoint.url;
    }

    if (body != null) {
      endpoint.body = body;
    }

    if (headers != null) {
      endpoint.headers = Object.assign({}, headers);
    }

    if (json != null) {
      endpoint.body = JSON.stringify(json);
      endpoint.headers['content-type'] = 'application/json';
    }
  }

  return endpoint;
}