import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { Color } from "../theme/Color.js";
export function overrideAvatar(theme) {
  theme.overrides.MuiAvatar = {
    root: _objectSpread(_objectSpread({}, theme.typography.h5), {}, {
      textTransform: 'uppercase',
      width: theme.spacing(5),
      height: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(4),
        height: theme.spacing(4)
      }
    }),
    colorDefault: {
      color: Color.Grey300,
      backgroundColor: Color.Silver300
    }
  };
}