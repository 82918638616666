import { Color } from "../theme/Color.js";
export function overridePaper(theme) {
  theme.props.MuiPaper = {
    elevation: 0
  };
  theme.overrides.MuiPaper = {
    elevation0: {
      border: "1px solid ".concat(Color.Silver400)
    }
  };
}