import { Color } from "../theme/Color.js";
export function overrideList(theme) {
  theme.overrides.MuiListItem = {
    root: {
      '&$selected, &$selected:hover': {
        backgroundColor: Color.Blue50
      },
      '& .MuiTouchRipple-root': {
        color: Color.Blue100
      }
    }
  };
}