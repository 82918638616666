import _objectSpread from "@babel/runtime/helpers/objectSpread2";
export function overrideMenu(theme) {
  theme.props.MuiMenu = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  };
  theme.overrides.MuiMenuItem = {
    root: _objectSpread(_objectSpread({}, theme.typography.body2), {}, {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    })
  };
}