import { useRef } from 'react';
export function useConstant(factory) {
  var ref = useRef();

  if (!ref.current) {
    ref.current = {
      value: factory()
    };
  }

  return ref.current.value;
}